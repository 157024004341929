import { graphql, PageProps } from "gatsby"
import * as React from "react"
// import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../templates/layout"
// import Sidebar from "../components/organisms/sidebar"
import Seo from "../templates/seo"
import { l_page_container, l_page_content } from "../components/atoms/__layout"

const NotFoundPage: React.FC<PageProps<Queries.BlogIndexQuery>> = ({
  data,
}) => {
  // const name = data?.site?.siteMetadata?.author?.name
  // const description = data?.site?.siteMetadata?.description
  // const breakpoints = useBreakpoint()
  return (
    <Layout>
      <Seo title="404: Not Found" />
      <div css={l_page_container}>
        <section css={l_page_content}>
          <h1>404: Not Found</h1>
          <p>You just hit a route that doesn't exist... the sadness.</p>
        </section>
        {/* {breakpoints.pc ? <Sidebar toc={false} author={name} description={description} /> : <div />} */}

      </div>
    </Layout>
  )
}

export default NotFoundPage


export const pageQuery = graphql`
  query NotFoundPage {
    site {
      siteMetadata {
        title
        description
        author {
          name
        }
      }
    }
  }
`
